#homepage-video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
}

#homepage-content {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    text-align: center;
}

#homepage-content img {
    margin: auto 10px;
    border-radius: 50%;
    max-width: 50%;
}

#homepage-title {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 25px;
    margin: auto 10px auto 10px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    color: white;
}

#homepage-title h1 {
    font-size: 5rem;
    margin: 0;
}

#homepage-title div {
    font-size: 1.8rem;
    margin: 0;
}

#homepage-div header {
    position: fixed;
    top: 0;
    left: 0;
}

#homepage-div footer {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    color: white;
}

#homepage-div footer a {
    color: #2f7ded;
}

#hamburger-menu #hamburger-menu-bar1,
#hamburger-menu #hamburger-menu-bar2,
#hamburger-menu #hamburger-menu-bar3 {
    background-color: #ffffff;
}
