#impressum-div {
    background-color: royalblue;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#impressum-content {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    flex: 1;
}

#impressum-content h1 {
    color: midnightblue;
    font-size: 4rem;
    margin: 0 0 20px;
}

#impressum-content h2 {
    color: midnightblue;
    font-size: 2rem;
    margin: 0 0 20px;
}

#impressum-content div {
    color: white;
    font-size: 1.8rem;
    margin: 0 0 30px;
}

#impressum-content a {
    color: white;
    font-size: 1.8rem;
}

#impressum-content a svg {
    margin-right: 10px;
}
