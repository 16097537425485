@media only screen and (max-width: 1199px) {
    #shcoding-content {
        width: 60%;
    }

    #shcoding-content h1 {
        font-size: 3.8rem;
    }

    #shcoding-content div {
        font-size: 1.6rem;
    }

    #shcoding-apps-content {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 991px) {
    #shcoding-content {
        width: 65%;
    }

    #shcoding-content h1 {
        font-size: 3.6rem;
    }
}

@media only screen and (max-width: 767px) {
    #shcoding-content {
        width: 80%;
    }

    #shcoding-content h1 {
        font-size: 3.2rem;
    }

    #shcoding-content div {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 549px) {
    #shcoding-content h1 {
        font-size: 3rem;
    }

    #shcoding-content div {
        font-size: 1.4rem;
    }

    #shcoding-apps-content {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 399px) {
    #shcoding-content h1 {
        font-size: 2.6rem;
    }

    #shcoding-content div {
        font-size: 1.2rem;
    }

    #shcoding-apps-content {
        font-size: 1.2rem;
    }
}
