@media only screen and (max-width: 1199px) {
    #impressum-content {
        width: 60%;
    }

    #impressum-content h1 {
        font-size: 3.8rem;
    }

    #impressum-content h2 {
        font-size: 1.9rem;
    }

    #impressum-content div, #impressum-content a {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 991px) {
    #impressum-content {
        width: 65%;
    }

    #impressum-content h1 {
        font-size: 3.6rem;
    }

    #impressum-content h2 {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 767px) {
    #impressum-content {
        width: 80%;
    }

    #impressum-content h1 {
        font-size: 3.2rem;
    }

    #impressum-content h2 {
        font-size: 1.6rem;
    }

    #impressum-content div, #impressum-content a {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 549px) {
    #impressum-content h1 {
        font-size: 3rem;
    }

    #impressum-content h2 {
        font-size: 1.5rem;
    }

    #impressum-content div, #impressum-content a {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 399px) {
    #impressum-content h1 {
        font-size: 2.6rem;
    }

    #impressum-content h2 {
        font-size: 1.3rem;
    }

    #impressum-content div, #impressum-content a {
        font-size: 1.2rem;
    }
}
