#aboutme-div {
    background-color: royalblue;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#aboutme-content {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    flex: 1;
}

#aboutme-content h1 {
    color: midnightblue;
    font-size: 4rem;
    margin: 0 0 20px;
}

#aboutme-content div {
    color: white;
    font-size: 1.8rem;
}

#aboutme-content div a {
    color: white;
    text-decoration: none;
}

#div-aboutme {
    margin-bottom: 30px;
}

.about-me-point {
    color: midnightblue;
}
