@media only screen and (max-width: 767px) {
    footer {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 399px) {
    footer {
        font-size: 0.8rem;
    }
}

@media only screen and (max-height: 650px) and (min-width: 767px) {
    footer {
        font-size: 0.8rem;
    }
}
