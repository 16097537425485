header {
    text-align: center;
    margin-bottom: 25px;
    width: 100%;
    z-index: 1;
}

header nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
}

header nav a {
    padding: 1rem 2rem;
    margin: 0.5rem 1rem;
    text-decoration: none;
    font-size: 1.3rem;
    color: white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
}

header nav a:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

header nav a:active {
    background-color: rgba(0, 0, 0, 0.8);
}

#hamburger-menu {
    display: none;
    margin-left: auto;
    padding: 1.5rem 1.5rem;
    cursor: pointer;
}

#hamburger-menu-bar2 {
    margin: 6px 0;
}

#hamburger-menu-bar1, #hamburger-menu-bar2, #hamburger-menu-bar3 {
    width: 35px;
    height: 5px;
    background-color: #222;
    transition: 0.4s;
}

.change #hamburger-menu-bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.change #hamburger-menu-bar2 {
    opacity: 0;
}

.change #hamburger-menu-bar3 {
    transform: translate(0, -11px) rotate(45deg);
}
